/* video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
  }
  

   */
   .img-bg{
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
    opacity: 50%;
   }

   .sjs-cont{
    z-index: 1;
   }


   .logo{
    width: 108px;
    height:80px;
    margin-top: 30px;
    margin-right: 20px;
   }

   .sjs-cont{
    display: flex;
    flex-direction: row;

   }
  .hero-container {
    /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
    height: 70vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain; 
    
  }
  
  h1 {
    color: black;
    font-size: 100px;
  }
  
  .hero-container > p {
    margin-top: 8px;
    color: black;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .hero-btns {
    margin-top: 32px;
  }
  
  .hero-btns .btn {
    margin: 6px;
  }
  
  .fa-play-circle {
    margin-left: 4px;
  }
  
  @media screen and (max-width: 960px) {
    h1 {
      font-size: 20px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .title {
      font-size: 80px;
    }

    .hero-container{
      transform: scale(.95);
      transition: 200ms;
    }

    .img-bg{
      width: 500px;
    }

  
    p {
      font-size: 16px;
    }

  }