:root{
    --primary: white;
}

.btn{
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
}

.btn--primary{
    background-color: black;
    color: black;
    border: 1px solid ;
}

.btn--outline{
    background-color: transparent;
    color: white;
    padding: 8px 20px;
    border: 1px solid;
    transition: all 0.3s ease-out;
}

.btn--medium:hover,.btn--large:hover{
    background: white;
    color: black;
    transition: 250ms;
}